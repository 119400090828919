import React from 'react'
import Helmet from 'react-helmet'

/**
 * @param {object} props
 * @param {object} props.site Site metadata
 * @param {string=} props.title Additional site title for sub page
 */
const Meta = ({ site, title }) => {
  const siteTitle = site.title
  title = title ? `${title} | ${siteTitle}` : siteTitle
  return (
    <Helmet
      // needed because we used "gatsby-plugin-offline"
      defer={false}
      title={title}
      meta={[
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: site.description,
        },
        {
          property: 'og:image',
          content: `${site.siteUrl}/icons/icon-512x512.png`,
        },
      ]}
    />
  )
}
export default Meta

import React from 'react'
import { Link } from 'gatsby'
import { Nav, Navbar } from 'react-bootstrap'
import classNames from 'classnames'

import styles from './style.module.scss'
import Icon from 'components/icon'

const expandBreakpoint = 'md'

export default function NavBar({ className, style, actionElement }) {
  return (
    <Navbar
      expand={expandBreakpoint}
      bg="primary"
      variant="dark"
      className={classNames('flex-row', className)}
      style={style}
    >
      <div className="container">
        <div aria-hidden={!Boolean(actionElement)} className="navbar-nav flex-grow-1 w-0">
          {actionElement && <Nav.Item>{actionElement}</Nav.Item>}
        </div>
        <div className="justify-content-center flex-grow-0">
          <Navbar.Brand as={Link} className="text-center mr-0" to="/">
            <Icon.Svg name="logo" fill="white" style={{ width: 32, transform: 'scale(1.3)' }} />
          </Navbar.Brand>
        </div>
        <div className={`d-${expandBreakpoint}-none flex-grow-1 w-0 d-flex`}>
          <Navbar.Toggle aria-controls="collapsable-navbar" className="ml-auto" />
        </div>
        <div className={`flex-grow-1 w-100 w-${expandBreakpoint}-0`}>
          <Navbar.Collapse id="collapsable-navbar">
            <Nav className={classNames('ml-auto', styles.flushRightNavItem)}>
              <Nav.Item>
                <Nav.Link as={Link} to="/products/" activeClassName="active">
                  สินค้า
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/about/" activeClassName="active">
                  เกี่ยวกับ
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/contact/" activeClassName="active">
                  ติดต่อ
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  )
}

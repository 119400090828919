import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTools, faPhone } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import classNames from 'classnames'

import styles from './style.module.scss'
import Gate from './icons/gate.inline.svg'
import Logo from './icons/logo.inline.svg'
import Roof from './icons/roof.inline.svg'
import Gutter from './icons/gutter.inline.svg'
import WroughtIron from './icons/fence.inline.svg'
import IronGrill from './icons/iron-grill.inline.svg'
import WindowFilm from './icons/window-film.inline.svg'
import Handrail from './icons/handrail.inline.svg'
import ElectricGate from './icons/electric-gate.inline.svg'
import BugWindowScreen from './icons/bug-window-screen.inline.svg'

library.add(faChevronLeft, faTools, faPhone)

/**
 * @param {object} props
 * @param {'chevron-left'|
 * 'tools'} props.name
 */
export default function Icon({ name }) {
  return (
    <i className={styles.icon} title={name}>
      <FontAwesomeIcon icon={['fas', name]} />
    </i>
  )
}

/**
 * @param {object} props
 * @param {'logo'|
 * 'gate'|
 * 'roof'|
 * 'fence'|
 * 'handrail'|
 * 'gutter'|
 * 'iron-grill'|
 * 'electric-gate'|
 * 'spare-part'|
 * 'window-film'|
 * 'bug-window-screen'} props.name
 * @param {string} [props.fill]
 * @param {import('react').CSSProperties} [props.style]
 * @param {string} [props.className]
 */
function SvgIcon({ name, fill, style, className }) {
  if (name === 'logo') {
    return (
      <SvgIconContainer style={style} className={className}>
        <Logo fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'gate') {
    return (
      <SvgIconContainer style={style} className={className}>
        <Gate fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'roof') {
    return (
      <SvgIconContainer style={style} className={className}>
        <Roof fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'fence') {
    return (
      <SvgIconContainer style={style} className={className}>
        <WroughtIron fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'handrail') {
    return (
      <SvgIconContainer style={style} className={className}>
        <Handrail fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'gutter') {
    return (
      <SvgIconContainer style={style} className={className}>
        <Gutter fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'iron-grill') {
    return (
      <SvgIconContainer style={style} className={className}>
        <IronGrill fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'electric-gate') {
    return (
      <SvgIconContainer style={style} className={className}>
        <ElectricGate fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'spare-part') {
    return (
      <SvgIconContainer
        style={style}
        className={classNames('d-flex justify-content-center align-items-center', className)}
      >
        <FontAwesomeIcon icon={['fas', 'tools']} style={{ width: '70%', height: '70%' }} />
      </SvgIconContainer>
    )
  }

  if (name === 'window-film') {
    return (
      <SvgIconContainer style={style} className={className}>
        <WindowFilm fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  if (name === 'bug-window-screen') {
    return (
      <SvgIconContainer style={style} className={className}>
        <BugWindowScreen fill={fill} className="w-100" />
      </SvgIconContainer>
    )
  }

  return null
}

const SvgIconContainer = styled.i`
  display: inline-block;
  margin: auto;
`

Icon.Svg = SvgIcon

import React from 'react'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import { motion } from 'framer-motion'
import styled, { ThemeProvider } from 'styled-components'

import UnAnimatedFixScrollJumpLayout from 'components/FixScrollJumpLayout'
import NavBar from 'components/NavBar'
import Footer from 'components/Footer'

export const PAGE_TRANSITION_DURATION = {
  slide: 1.5,
  fade: 0.5,
}

const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
}

/**
 * @param {object} props
 * @param {any} props.children
 * @param {import('react').ReactElement} [props.actionElement]
 * @param {import('react').CSSProperties} [props.style] This can also be passed from framer-motion
 */
export default function Layout({ children, actionElement }) {
  // page transition
  const transitionState = useTransitionState()
  const defaultTransitionMethod = 'slide'
  const transitionMethod = transitionState.entry.state.transitionMethod || defaultTransitionMethod

  const variants = {
    slide: {
      entered: { y: 0, opacity: 1 },
      entering: { y: 0, opacity: 1 },
      exited: { y: '100%', opacity: 0 },
    },
    fade: {
      entered: { opacity: 1 },
      entering: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 },
    },
  }

  const transition = {
    duration: PAGE_TRANSITION_DURATION[transitionMethod],
    ease: 'easeInOut',
  }

  const shouldAnimate = transitionState.entry.length > 0 || transitionState.entry.delay > 0
  const animate = shouldAnimate ? transitionState.transitionStatus : 'entered'

  return (
    <FixScrollJumpLayout
      initial={false}
      animate={animate}
      variants={variants[transitionMethod]}
      transition={transition}
      className="h-100"
    >
      <ThemeProvider theme={theme}>
        <div className="d-flex flex-column h-100" style={{ paddingTop: 55 }}>
          <AutoHideNavBar style={{ flexShrink: 0 }} actionElement={actionElement} />
          {children}
          <Footer />
        </div>
      </ThemeProvider>
    </FixScrollJumpLayout>
  )
}

const FixScrollJumpLayout = motion.custom(UnAnimatedFixScrollJumpLayout)

const AutoHideNavBar = styled(NavBar)`
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
`
